import React from 'react'

export default function Bank(props) {
    return (
        <>
            <div className="flex">
                <div className="flex-1 w-64 text-left">Bank Name</div>
                <div className="flex-1 w-32 text-left">{props.data.bankName}</div>
            </div>
        </>
    )
}
