import React from 'react'
import axios from 'axios'

import { retrieveConfig } from '../../config/config'
import { tokenisationPublicKey } from '.'
import { themeBuilder } from '../../utils/theme-builder'
import { sdkCallback } from './sdkCallback'
import { paymentTypeIdToLabel } from '../../utils/payment-type-helper'
import { localFormattedDateTime } from '../../utils/date'
import ModalContent from './modal-content'

export class PaymentModel extends React.Component {
    dataMesh = null
    chargeId = null

    constructor(props) {
        super(props)

        this.state = {
            selectedConfig: null,
        }

        // Initiate this component with defaults
        const runtimeEnvironment = this.state.selectedConfig
        this.state = {
            show: false,
            showGeneralError: false,
            showFailureScreen: false,
            showReceiptScreen: false,
            isLoaded: false,
            paymentFlowMethod: runtimeEnvironment?.paymentFlowMethod ?? null,
            defaultPaymentMethod: runtimeEnvironment?.defaultpaymentMethod ?? null,
            currencyCode: runtimeEnvironment?.currencyCode,
            orderId: '',
            payerName: window.env.REACT_APP_PAYER_NAME,
            payerEmail: window.env.REACT_APP_PAYER_EMAIL,
            payerTelephone: window.env.REACT_APP_PAYER_TELEPHONE,
            merchantId: runtimeEnvironment?.merchantId ?? null,
            configId: runtimeEnvironment?.configId ?? null,
            publicKey: runtimeEnvironment?.sdkPublicKey ?? null,
            showEditConfigUi: false,
            showPaymentScreen: true,
            showOrderFailed: false,
            chargePayload: null,
            chargeId: null,
            formData: null,
            formErrors: {
                code: false,
            },
        }
    }

    async componentDidMount() {
        const selectedConfig = await retrieveConfig()
        this.setState({ selectedConfig })
    }

    loadSdk(formData) {
        window.setTimeout(() => {
            if (!this.state.isLoaded) {
                console.error('SDK failed to load, please check your configuration and try again.')
                this.setState({
                    isLoaded: true,
                    showGeneralError: true,
                    showFailureScreen: false,
                    showPaymentScreen: false,
                    message: 'There was an issue, please try again later.',
                })
            }
        }, 10000)

        this.setState({
            showEditConfigUi: false,
            chargePayload: null,
            receiptDetails: null,
        })

        // Retrieve the order details from the merchant backend using Charge API.
        const orderAmount = formData?.amount || 0
        const serviceId = this.state.selectedConfig.merchantId
        const domain = this.state.selectedConfig.domain
        const { payerName, payerEmail, payerTelephone, currencyCode } = this.state

        axios
            .post(window.env.REACT_APP_DEMO_BACKEND_URL + '/order', {
                orderAmount,
                serviceId,
                domain,
                payerName,
                payerEmail,
                payerTelephone,
                currencyCode,
                formData,
            })
            .then(res => {
                window.DatameshAPICallback = () => {
                    this.setupSdk(res.data.orderToken)
                }

                // Give it a chance for the order to get created in memory
                window.DatameshAPICallback()
            })
            .catch(e => {
                console.error('Order creation error, SDK can not be created.', e)
            })
    }

    createdCallback = response => {
        const chargeId = (this.chargeId = response.charge_id)
        this.setState({ showFailureScreen: false, showPaymentScreen: false, showReceiptScreen: false, chargeId })
    }

    initialCallback = response => {
        this.setState({ isLoaded: true })

        if (response.result !== 'success') {
            console.error('INITIATION ERROR - Datamesh was unable to initiate the SDK widget, please refresh and try again.')
        }
    }

    errorCallback = response => {
        console.error('Error occurred in loading the SDK', response)
    }

    paymentCompletedCallback = response => {
        if (response.status) {
            if (response?.status?.status === 'payment.completed') {
                axios
                    .get(window.env.REACT_APP_DEMO_BACKEND_URL + '/payment/receipt/' + this.chargeId)
                    .then(receiptResponse => {
                        const data = receiptResponse.data
                        const cardEndingNumber = data.payments[0].payment_instrument.instrument.card_last_four

                        const paymentType = data.payments[0].payment_instrument.instrument_type

                        sessionStorage.setItem('lastPayMethod', paymentType)

                        let payDetails
                        if (paymentType === 'card_instrument') {
                            payDetails = {
                                cardholderName: data.payments[0].payment_instrument.payer.entity_details.name.full_name,
                                cardholderNumber: '....' + cardEndingNumber,
                                expiryDate: data.payments[0].payment_instrument.instrument.expiration_date,
                            }
                        } else {
                            payDetails = {
                                bankName: data.payments[0].payment_instrument.instrument.bank_code,
                            }
                        }

                        // TODO look into passing response message and code, omit for now
                        const commonPaymentFields = {
                            receiptNo: data?.payments[0]?.ledger_id,
                            // status: 'Approved',
                            // response: 'Honour with identification (08)',
                            paymentDate: localFormattedDateTime(),
                            paymentInstrument: {
                                ...data?.payments[0]?.payment_instrument?.instrument,
                                instrument_type: data?.payments[0]?.payment_instrument?.instrument_type,
                            },

                            paymentAmount: Math.round(data?.order?.order_amount?.value / 100),
                            paymentType: paymentTypeIdToLabel(paymentType),
                        }

                        this.setState({
                            showReceiptScreen: true,
                            showPaymentScreen: false,
                            receiptDetails: {
                                ...commonPaymentFields,
                                ...payDetails,
                            },
                        })

                        window.DataMesh.destroy()
                    })
                    .catch(e => {
                        console.error('Order creation error, SDK can not be created.', e)
                    })
            }
        }
    }

    paymentFailedCallback = response => {
        this.setState({
            showFailureScreen: true,
            showPaymentScreen: false,
            message: response?.extra.error.error_message,
        })
    }

    setupSdk(orderToken) {
        let sdkConfig = {
            container: '#paymentDmgSdkBox',
            theme: themeBuilder(this.state.selectedConfig),
            merchant_id: this.state.selectedConfig.merchantId,
            config_id: this.state.selectedConfig.configId,
            public_key: this.state.selectedConfig.sdkPublicKey,
            order_token: orderToken,
            payment_flow_method: this.state.selectedConfig.paymentFlowMethod,
            tokenisation_public_key: tokenisationPublicKey,
            paymentMethod: this.state.selectedConfig.defaultPaymentMethod,
            callback: () =>
                sdkCallback(
                    this.createdCallback,
                    this.initialCallback,
                    this.errorCallback,
                    this.paymentCompletedCallback,
                    this.paymentFailedCallback,
                ),
        }

        window.DataMesh.load(sdkConfig)
    }

    toggle = data => {
        if (this.state.show) {
            // Right now it's much safer to implement as a reload of window/tab then a destroy of window because
            // destroy doesn't guarantee an actual full destroy with side effects occurring with double up SDK
            window.location.reload(false)
        }

        this.setState(currentState => {
            return {
                showReceiptScreen: false,
                showPaymentScreen: true,
                show: !currentState.show,
                formData: data,
            }
        })

        if (this.state.showPaymentScreen) {
            this.loadSdk(data)
        }

        // let demoPaymentForm = document.getElementById('demoPaymentForm')
        // demoPaymentForm.removeEventListener('submit', this.makePayment)
        // demoPaymentForm.addEventListener('submit', this.makePayment)
    }

    render() {
        const { show, showReceiptScreen, chargeId, receiptDetails, showFailureScreen, message, showGeneralError, isLoaded } =
            this.state

        if (!show) {
            return null
        }

        return (
            <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div
                        className="relative w-full h-max md:w-auto md:my-6 mx-auto md:max-w-3xl"
                        style={{ width: '100%', maxWidth: '470px' }}
                    >
                        <div
                            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                            style={{ minHeight: '400px' }}
                        >
                            <ModalContent
                                showReceiptScreen={showReceiptScreen}
                                chargeId={chargeId}
                                receiptDetails={receiptDetails}
                                showFailureScreen={showFailureScreen}
                                message={message}
                                showGeneralError={showGeneralError}
                                isLoaded={isLoaded}
                            />
                        </div>
                    </div>
                </div>
                <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
            </>
        )
    }
}
