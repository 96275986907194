import React from 'react'
const currencySymbols = {
    AUD: '$',
    USD: '$',
    EUR: '€',
    GBP: '£',
    INR: '₹',
    // add more currencies as needed
}

function getCurrencySymbol(currencyCode) {
    return currencySymbols[String(currencyCode)] || '₹' // default to '$' if the currency code is not found
}

// usage

export const CurrencySymbol = ({ currencyCode, isCurrency }) => {
    const symbol = getCurrencySymbol(currencyCode)

    return isCurrency ? (
        <span className="z-10 h-full font-normal absolute text-center text-slate-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-1 py-1 ">
            {symbol}
        </span>
    ) : null
}
