import React from 'react'
import { usePDF, Resolution, Margin } from 'react-to-pdf'

let IndianRupeesAmount = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
})
export default function VirtualAccountPaymentDetails({ paymentDetails }) {
    const pdfOptions = {
        resolution: Resolution.HIGH,
        page: {
            margin: Margin.SMALL,
        },
    }
    const { toPDF, targetRef } = usePDF({
        filename: `va-receipt-${paymentDetails?.paymentInstrument?.payer_id}.pdf`,
        ...pdfOptions,
    })
    const { toPDF: toPrint, targetRef: printRef } = usePDF({
        filename: `va-print-receipt-${paymentDetails?.paymentInstrument?.payer_id}.pdf`,
        method: 'open',
        ...pdfOptions,
    })

    return (
        <div className="virtual-account-details">
            <h2 className="fs-20 box-heading">Payment Instruction</h2>
            <div ref={printRef}>
                <div className="payment-details" ref={targetRef}>
                    <div className="pd-row flex">
                        <div className="flex-1 w-64 text-left">Beneficiary Name</div>
                        <div className="flex-1 w-32 text-left">{paymentDetails?.paymentInstrument?.payee_bank_account_name}</div>
                    </div>
                    <div className="pd-row flex">
                        <div className="flex-1 w-64 text-left">Beneficiary Account Number</div>
                        <div className="flex-1 w-32 text-left">{paymentDetails?.paymentInstrument?.payer_id}</div>
                    </div>
                    <div className="pd-row flex">
                        <div className="flex-1 w-64 text-left">Beneficiary Bank Identifier</div>
                        <div className="flex-1 w-32 text-left">{paymentDetails?.paymentInstrument?.payee_bank_identifier}</div>
                    </div>
                    <div className="pd-row flex">
                        <div className="flex-1 w-64 text-left">Amount</div>
                        <div className="flex-1 w-32 text-left">{IndianRupeesAmount.format(paymentDetails?.paymentAmount)}</div>
                    </div>
                    <div className="pd-row flex">
                        <div className="flex-1 w-64 text-left">Reference / Payment ID</div>
                        <div className="flex-1 w-32 text-left">
                            {paymentDetails?.paymentInstrument?.payer_account_details}
                            <div className="pd-subtext">You must include this reference so we can identify your payment.</div>
                        </div>
                    </div>

                    <div className="note">
                        <p>
                            The above payment will be reflected as payment pending until the payment is received. The payment
                            should be made to the above account within 48 hours of generation of this page and payments received
                            after 48 hours may be rejected/returned.
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex">
                <button className="flex-1 w-32 text-left button primary va-btn" onClick={() => toPDF()}>
                    Download
                </button>
                <button className="flex-1 w-32 text-left button primary va-btn" onClick={() => toPrint()}>
                    Print
                </button>
            </div>
        </div>
    )
}
