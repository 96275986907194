import React from 'react'

export default function Card(props) {
    return (
        <>
            <div className="flex">
                <div className="flex-1 w-64 text-left">Cardholder Number</div>
                <div className="flex-1 w-32 text-left">{props.data.cardholderNumber}</div>
            </div>
            <div className="flex">
                <div className="flex-1 w-64 text-left">Expiry Date (mm/yy)</div>
                <div className="flex-1 w-32 text-left">{props.data.expiryDate}</div>
            </div>
        </>
    )
}
